import { FaUser } from "react-icons/fa";
import { MdOutlineStars } from "react-icons/md";
import { TbTargetArrow } from "react-icons/tb";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { adminArr } from "./menuItems";
import { useProfileQuery } from "../Queries/auth/useProfileQuery";
// const { data: auth } = useProfileQuery();

export const crmMenuItems = [
  
  {
    label: "Dashboard",
    href: "/",
    icon: <TbTargetArrow size={"1.4rem"} />,
  },
  {
    label: "Leads",
    href: "/lead",
    icon: <MdOutlineStars size={"1.4rem"} />,
  },
  {
    label: "Agents",
    href: "/agents",
    icon: <FaUser size={"1.4rem"} />,
  },
  {
    label: "Meetings",
    href: "/",
    icon: <TfiHeadphoneAlt size={"1.4rem"} />,
  },
]

export const leadHeaderItems = [
  {
   label: "All",
   value: "all",
   color: "#3e3e3e",
  },
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "Warm",
    value: "warm",
    color: "#FF8A00",
  },
  {
    label: "Reassigned",
    value: "reassigned",
    color: "#0095FF",
  },
  {
    label: "F2F Scheduled",
    value: "f2fScheduled",
    color: "#740707",
  },
  {
    label: "F2F Done",
    value: "f2fDone",
    color: "#4ABC04",
  },
  
  {
    label: "Video Call Scheduled",
    value: "videoCallScheduled",
    color: "#FF0000",
  },
  {
    label: "Video Call Done",
    value: "videoCallDone",
    color: "#FF0000",
  },
  {
    label: "Follow up",
    value: "followUp",
    color: "#F6CF03",
  },
  {
    label: "Cold",
    value: "cold",
    color: "#3BBFF7",
  },
  {
    label: "DNP",
    value: "dnp",
    color: "#A70479",
  },
  {
    label: "DNP Final",
    value: "dnpFinal",
    color: "#5F04A7",
  },
  {
    label: "Delay",
    value: "delay",
    color: "#AC5E03",
  },
  {
    label: "Not Interested",
    value: "notInterested",
    color: "#02276E",
  },
  {
    label: "Win",
    value: "win",
    color: "#068137",
  },
  {
    label: "Do Not Distrub",
    value: "doNotDisturb",
    color: "#B90202",
  },
];

export const leadHeaderAllItems = [
  {
   label: "All",
   value: "all",
   color: "#3e3e3e",
  },
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "Warm",
    value: "warm",
    color: "#FF8A00",
  },
  {
    label: "Reassigned",
    value: "reassigned",
    color: "#0095FF",
  },
  {
    label: "F2F Scheduled",
    value: "f2fScheduled",
    color: "#740707",
  },
  {
    label: "F2F Done",
    value: "f2fDone",
    color: "#4ABC04",
  },
  
  {
    label: "Video Call Scheduled",
    value: "videoCallScheduled",
    color: "#FF0000",
  },
  {
    label: "Video Call Done",
    value: "videoCallDone",
    color: "#FF0000",
  },
  {
    label: "Follow up",
    value: "followUp",
    color: "#F6CF03",
  },
  {
    label: "Cold",
    value: "cold",
    color: "#3BBFF7",
  },
  {
    label: "DNP",
    value: "dnp",
    color: "#A70479",
  },
  {
    label: "DNP Final",
    value: "dnpFinal",
    color: "#5F04A7",
  },
  {
    label: "Delay",
    value: "delay",
    color: "#AC5E03",
  },
  {
    label: "Not Interested",
    value: "notInterested",
    color: "#02276E",
  },
  {
    label: "Win",
    value: "win",
    color: "#068137",
  },
  {
    label: "Do Not Distrub",
    value: "doNotDisturb",
    color: "#B90202",
  },
];

export const leadHeadersItems = [
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "Warm",
    value: "warm",
    color: "#FF8A00",
  },
  {
    label: "Follow up",
    value: "followUp",
    color: "#F6CF03",
  },
  {
    label: "Cold",
    value: "cold",
    color: "#3BBFF7",
  },
  {
    label: "DNP",
    value: "dnp",
    color: "#A70479",
  },
  {
    label: "DNP Final",
    value: "dnpFinal",
    color: "#5F04A7",
  },
  {
    label: "Delay",
    value: "delay",
    color: "#AC5E03",
  },
  {
    label: "Not Interested",
    value: "notInterested",
    color: "#02276E",
  },
  {
    label: "Do Not Distrub",
    value: "doNotDisturb",
    color: "#B90202",
  },
];

export const leadHeadersitems = [
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "Warm",
    value: "warm",
    color: "#FF8A00",
  },
  {
    label: "Follow up",
    value: "followUp",
    color: "#F6CF03",
  },
  {
    label: "Cold",
    value: "cold",
    color: "#3BBFF7",
  },
  {
    label: "DNP",
    value: "dnp",
    color: "#A70479",
  },
  {
    label: "DNP Final",
    value: "dnpFinal",
    color: "#5F04A7",
  },
  {
    label: "Delay",
    value: "delay",
    color: "#AC5E03",
  },
  {
    label: "Not Interested",
    value: "notInterested",
    color: "#02276E",
  },
  {
    label: "Win",
    value: "win",
    color: "#068137",
  },
  {
    label: "Do Not Distrub",
    value: "doNotDisturb",
    color: "#B90202",
  },
];

export const meetHeaderItems = [
  {
    label: "Scheduled",
    value: "scheduled",
    color: "#E9A80A",
  },
  {
    label: "Rescheduled",
    value: "rescheduled",
    color: "#FF8A00",
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#0095FF",
  },
  {
    label: "Conducted",
    value: "conducted",
    color: "#4ABC04",
  }
];

export const meetHeadersItems = [
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#0095FF",
  },
  {
    label: "Conducted",
    value: "conducted",
    color: "#4ABC04",
  }
];

export const meetingType =[
  {
    label: "Face To Face",
    value: "faceToFace"
  },
  {
    label: "Video Call",
    value: "videoCall",
  }
]
export const socialMediaSource =[
  {
    label: "Facebook",
    value: "facebook"
  },
  {
    label: "Youtube",
    value: "youtube",
  },
  {
    label: "Tiktok",
    value: "tiktok",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "LinkedIn",
    value: "linkedIn",
  },
  {
    label: "Other",
    value: "other",
  }
]
export const platformSocialMedia =[
  {
    label:"Self",
    value:"self",
  },
  {
    label:"upload",
    value:"upload",
  },
  {
    label:"Social Media",
    value:"socialMedia",
  }
]
export const reportHeaderItems = [
  {
    label: "Leads",
    value: "leads",
    color: "#9A4D49",
  },
  // ...(adminArr.includes(auth?.role) ? [
    {
      label: "Social Media",
      value: "socialMedia",
      color: "#9A4D49",
    },
  // ] : []),
  {
    label: "Meetings",
    value: "meetings",
    color: "#9A4D49",
  },
  {
    label: "Bookings",
    value: "booking",
    color: "#9A4D49",
  },
  {
    label: "Accounts",
    value: "payments",
    color: "#9A4D49",
  }
]

export const reportHeaderAgentsItems = [
  {
    label: "Leads",
    value: "leads",
    color: "#9A4D49",
  },
  {
    label: "Meetings",
    value: "meetings",
    color: "#9A4D49",
  },
  {
    label: "Bookings",
    value: "booking",
    color: "#9A4D49",
  },
]
export const BookingHeader = [
  {
    label: "Draft",
    value: "draft",
    color: "#0095FF",
  },
  {
    label: "Applied",
    value: "applied",
    color: "#FF8A00",
  },
  {
    label: "Approved",
    value: "approved",
    color: "#4ABC04",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "#FF0000",
  },
  {
    label: "Closed",
    value: "closed",
    color: "#0095FF",
  }, 
]

export const BookingHeaders = [
  {
    label: "Rejected",
    value: "rejected",
    color: "#FF0000",
  },
  {
    label: "Closed",
    value: "closed",
    color: "#0095FF",
  }
]