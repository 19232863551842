import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { editLead, getLeadDetailsById, getLeads } from "../useFunction/leadFunction";
// import { useQuery } from "@chakra-ui/react";

export const useLeadQuery = ({ search, status, startCreatedDate, endCreatedDate, startAssignedDate, endAssignedDate, startUpdatedDate, endUpdatedDate,source, sortBy, sortOrder,agentArrFilter,countryCode }) => {

  let res = useInfiniteQuery({
    queryKey: ["leads", status, search],
    queryFn: ({ pageParam = 1 }) => getLeads({ pageParam, search, status, startCreatedDate, endCreatedDate,startAssignedDate,endAssignedDate,startUpdatedDate,endUpdatedDate, source, sortBy, sortOrder, agentArrFilter, countryCode }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext ? parseInt(lastPage?.pagination?.currentPage) + 1 : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = []
  let totalItems = 0;
  if (res) {
    data = res?.data?.pages?.map(page => page?.data).flat();
    totalItems = res?.data?.pages?.[0]?.pagination?.totalItem || 0;
  }
  return { ...res, data, totalItems }
}


  export const useGetLeadById=(id)=>{
    let res = useQuery({
      queryKey:["leads", id],
      queryFn:()=>getLeadDetailsById(id),
      staleTime: 1000 * 60 * 10,
      enabled:!!id
    })
    return res
  }

  export const useEditLead = (id, data) => {
    return useQuery({
      queryKey: ["editLead", id],
      queryFn: () => editLead(id, data), // The API call to edit the lead
      enabled: !!id, // Only run when the ID and data are available
      staleTime: 1000 * 60 * 5, // Adjust based on how frequently you need to refresh the edit result
    });
  };