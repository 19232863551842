import { API_AXIOS } from "../../../http/interceptor";
import { showError, showSuccess } from "../../../utils/toastHelpers";

export const getLeads = async ({
    pageParam = 1,
    search = '',
    status,
    countryCode,
    startCreatedDate, endCreatedDate, startAssignedDate, endAssignedDate, startUpdatedDate, endUpdatedDate, source, sortBy, sortOrder, agentArrFilter
  }) => {
    try {
      const { data } = await API_AXIOS.get(`lead`, {
        params: {
          page: pageParam,
          search,
          status,
          countryCode,
          startCreatedDate, endCreatedDate, startAssignedDate, endAssignedDate, startUpdatedDate,endUpdatedDate, source, sortBy, sortOrder, agentArrFilter
        },
      });
      return data || {};
    } catch (error) {
  
      console.log(error);
    }
  };


  export const getLeadDetailsById = async (id) => {
    try {
      const res = await API_AXIOS.get(`lead/${id}`);
      return res.data;
    } catch (error) {
      console.error('Lead Details Error:', error);
      throw error;
    }
  };

 export const editLead = async (data) => {
  try {
    const response = await API_AXIOS.post(`/lead/editLead`, data); // POST request with data
    showSuccess(response?.data?.message);
    return response.data;
  } catch (error) {
    console.error('Error editing lead:', error);
    showError(error?.response?.data?.message);
    throw error;
  }
};