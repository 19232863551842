import dayjs from "dayjs";
import moment from "moment";


export const dateFormate = (date, format = "DD/MM/YYYY") => {
    let a = !!date ? moment(date).format(format) : 'N/A';
    return a;
}

export const formateDateDayjs = (date) => {
    if(!date) return 'N/A'

    return dayjs(date).toDate().toLocaleDateString()
}

export const formateTimeDayjs = (date) => {
    if(!date) return 'N/A'
    return dayjs(date).toDate().toLocaleTimeString()
}


export const countryList = [
    { code: "1", country: "USA/Canada", label: "usa_and_canada" },
    { code: "7", country: "Russia/Kazakhstan", label: "russia_and_kazakhstan" },
    { code: "20", country: "Egypt", label: "egypt" },
    { code: "27", country: "South Africa", label: "south_africa" },
    { code: "30", country: "Greece", label: "greece" },
    { code: "31", country: "Netherlands", label: "netherlands" },
    { code: "32", country: "Belgium", label: "belgium" },
    { code: "33", country: "France", label: "france" },
    { code: "34", country: "Spain", label: "spain" },
    { code: "36", country: "Hungary", label: "hungary" },
    { code: "39", country: "Italy", label: "italy" },
    { code: "40", country: "Romania", label: "romania" },
    { code: "41", country: "Switzerland", label: "switzerland" },
    { code: "43", country: "Austria", label: "austria" },
    { code: "44", country: "United Kingdom", label: "united_kingdom" },
    { code: "45", country: "Denmark", label: "denmark" },
    { code: "46", country: "Sweden", label: "sweden" },
    { code: "47", country: "Norway", label: "norway" },
    { code: "48", country: "Poland", label: "poland" },
    { code: "49", country: "Germany", label: "germany" },
    { code: "51", country: "Peru", label: "peru" },
    { code: "52", country: "Mexico", label: "mexico" },
    { code: "53", country: "Cuba", label: "cuba" },
    { code: "54", country: "Argentina", label: "argentina" },
    { code: "55", country: "Brazil", label: "brazil" },
    { code: "56", country: "Chile", label: "chile" },
    { code: "57", country: "Colombia", label: "colombia" },
    { code: "58", country: "Venezuela", label: "venezuela" },
    { code: "60", country: "Malaysia", label: "malaysia" },
    { code: "61", country: "Australia", label: "australia" },
    { code: "62", country: "Indonesia", label: "indonesia" },
    { code: "63", country: "Philippines", label: "philippines" },
    { code: "64", country: "New Zealand", label: "new_zealand" },
    { code: "65", country: "Singapore", label: "singapore" },
    { code: "66", country: "Thailand", label: "thailand" },
    { code: "81", country: "Japan", label: "japan" },
    { code: "82", country: "South Korea", label: "south_korea" },
    { code: "84", country: "Vietnam", label: "vietnam" },
    { code: "86", country: "China", label: "china" },
    { code: "90", country: "Turkey", label: "turkey" },
    { code: "91", country: "India", label: "india" },
    { code: "92", country: "Pakistan", label: "pakistan" },
    { code: "93", country: "Afghanistan", label: "afghanistan" },
    { code: "94", country: "Sri Lanka", label: "sri_lanka" },
    { code: "95", country: "Myanmar", label: "myanmar" },
    { code: "98", country: "Iran", label: "iran" },
    { code: "211", country: "South Sudan", label: "south_sudan" },
    { code: "212", country: "Morocco", label: "morocco" },
    { code: "213", country: "Algeria", label: "algeria" },
    { code: "216", country: "Tunisia", label: "tunisia" },
    { code: "218", country: "Libya", label: "libya" },
    { code: "220", country: "Gambia", label: "gambia" },
    { code: "221", country: "Senegal", label: "senegal" },
    { code: "222", country: "Mauritania", label: "mauritania" },
    { code: "223", country: "Mali", label: "mali" },
    { code: "224", country: "Guinea", label: "guinea" },
    { code: "225", country: "Ivory Coast", label: "ivory_coast" },
    { code: "226", country: "Burkina Faso", label: "burkina_faso" },
    { code: "227", country: "Niger", label: "niger" },
    { code: "228", country: "Togo", label: "togo" },
    { code: "229", country: "Benin", label: "benin" },
    { code: "230", country: "Mauritius", label: "mauritius" },
    { code: "231", country: "Liberia", label: "liberia" },
    { code: "232", country: "Sierra Leone", label: "sierra_leone" },
    { code: "233", country: "Ghana", label: "ghana" },
    { code: "234", country: "Nigeria", label: "nigeria" },
    { code: "235", country: "Chad", label: "chad" },
    { code: "236", country: "Central African Republic", label: "central_african_republic" },
    { code: "237", country: "Cameroon", label: "cameroon" },
    { code: "238", country: "Cape Verde", label: "cape_verde" },
    { code: "239", country: "Sao Tome and Principe", label: "sao_tome_and_principe" },
    { code: "240", country: "Equatorial Guinea", label: "equatorial_guinea" },
    { code: "241", country: "Gabon", label: "gabon" },
    { code: "242", country: "Congo", label: "congo" },
    { code: "243", country: "Democratic Republic of the Congo", label: "democratic_republic_of_the_congo" },
    { code: "244", country: "Angola", label: "angola" },
    { code: "245", country: "Guinea-Bissau", label: "guinea_bissau" },
    { code: "246", country: "British Indian Ocean Territory", label: "british_indian_ocean_territory" },
    { code: "248", country: "Seychelles", label: "seychelles" },
    { code: "249", country: "Sudan", label: "sudan" },
    { code: "250", country: "Rwanda", label: "rwanda" },
    { code: "251", country: "Ethiopia", label: "ethiopia" },
    { code: "252", country: "Somalia", label: "somalia" },
    { code: "253", country: "Djibouti", label: "djibouti" },
    { code: "254", country: "Kenya", label: "kenya" },
    { code: "255", country: "Tanzania", label: "tanzania" },
    { code: "256", country: "Uganda", label: "uganda" },
    { code: "257", country: "Burundi", label: "burundi" },
    { code: "258", country: "Mozambique", label: "mozambique" },
    { code: "260", country: "Zambia", label: "zambia" },
    { code: "261", country: "Madagascar", label: "madagascar" },
    { code: "262", country: "Reunion/Mayotte", label: "reunion_mayotte" },
    { code: "263", country: "Zimbabwe", label: "zimbabwe" },
    { code: "264", country: "Namibia", label: "namibia" },
    { code: "265", country: "Malawi", label: "malawi" },
    { code: "266", country: "Lesotho", label: "lesotho" },
    { code: "267", country: "Botswana", label: "botswana" },
    { code: "268", country: "Eswatini", label: "eswatini" },
    { code: "269", country: "Comoros", label: "comoros" },
    { code: "290", country: "Saint Helena", label: "saint_helena" },
    { code: "291", country: "Eritrea", label: "eritrea" },
    { code: "297", country: "Aruba", label: "aruba" },
    { code: "298", country: "Faroe Islands", label: "faroe_islands" },
    { code: "299", country: "Greenland", label: "greenland" },
    { code: "350", country: "Gibraltar", label: "gibraltar" },
    { code: "351", country: "Portugal", label: "portugal" },
    { code: "352", country: "Luxembourg", label: "luxembourg" },
    { code: "353", country: "Ireland", label: "ireland" },
    { code: "354", country: "Iceland", label: "iceland" },
    { code: "355", country: "Albania", label: "albania" },
    { code: "356", country: "Malta", label: "malta" },
    { code: "357", country: "Cyprus", label: "cyprus" },
    { code: "358", country: "Finland", label: "finland" },
    { code: "359", country: "Bulgaria", label: "bulgaria" },
    { code: "370", country: "Lithuania", label: "lithuania" },
    { code: "371", country: "Latvia", label: "latvia" },
    { code: "372", country: "Estonia", label: "estonia" },
    { code: "373", country: "Moldova", label: "moldova" },
    { code: "374", country: "Armenia", label: "armenia" },
    { code: "375", country: "Belarus", label: "belarus" },
    { code: "376", country: "Andorra", label: "andorra" },
    { code: "377", country: "Monaco", label: "monaco" },
    { code: "378", country: "San Marino", label: "san_marino" },
    { code: "380", country: "Ukraine", label: "ukraine" },
    { code: "381", country: "Serbia", label: "serbia" },
    { code: "382", country: "Montenegro", label: "montenegro" },
    { code: "383", country: "Kosovo", label: "kosovo" },
    { code: "385", country: "Croatia", label: "croatia" },
    { code: "386", country: "Slovenia", label: "slovenia" },
    { code: "387", country: "Bosnia and Herzegovina", label: "bosnia_and_herzegovina" },
    { code: "389", country: "North Macedonia", label: "north_macedonia" },
    { code: "420", country: "Czech Republic", label: "czech_republic" },
    { code: "421", country: "Slovakia", label: "slovakia" },
    { code: "423", country: "Liechtenstein", label: "liechtenstein" },
    { code: "973", country: "Bahrain", label: "bahrain" },
    { code: "965", country: "Kuwait", label: "kuwait" },
    { code: "968", country: "Oman", label: "oman" },
    { code: "974", country: "Qatar", label: "qatar" },
    { code: "966", country: "Saudi Arabia", label: "saudi_arabia" },
    { code: "971", country: "United Arab Emirates", label: "united_arab_emirates" },
    // Add more as needed for additional countries.
  ];
  