import React, { useState, useEffect } from "react";
import {
  parsePhoneNumberFromString,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import Select from "react-select";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";

const getFlagImage = (countryCode) =>
  `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;

const PhoneInputField = ({
  id,
  value,
  onChange,
  label,
  error,
  backgroundColor = "white",
  disabled = false,
  defaultCountry = "AE",
  ...props
}) => {
  const [localError, setLocalError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [phoneNumber, setPhoneNumber] = useState("");

  const countryOptions = getCountries().map((c) => ({
    value: c,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={getFlagImage(c)}
          alt={c}
          style={{ width: 20, height: 15, marginRight: 8 }}
        />
        +{getCountryCallingCode(c)}
      </div>
    ),
  }));

  useEffect(() => {
    if (!value) return;

    const parsedNumber = parsePhoneNumberFromString(value);

    if (parsedNumber) {
      setSelectedCountry(parsedNumber.country || defaultCountry);
      setPhoneNumber(parsedNumber.nationalNumber);
    } else {
      setPhoneNumber(value); // Keep raw input if parsing fails
    }
  }, [value]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
    setPhoneNumber(""); // Reset input to avoid format issues
    setLocalError("");
  };

  const handlePhoneChange = (e) => {
    const cleanedNumber = e.target.value.replace(/\s+/g, "");
    setPhoneNumber(cleanedNumber);
  };

  useEffect(() => {
    if (!phoneNumber) return;

    const parsedNumber = parsePhoneNumberFromString(
      phoneNumber,
      selectedCountry
    );

    if (parsedNumber && parsedNumber.isValid()) {
      setLocalError("");
      const formattedNumber = parsedNumber.formatInternational();

      if (formattedNumber !== value) {
        onChange(formattedNumber);
      }
    } else {
      setLocalError("Invalid phone number format");
    }
  }, [selectedCountry, phoneNumber]);

  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderColor: "#CCCCCC",
          borderRadius: "5px",
          backgroundColor: "rgba(249, 249, 249, 1)",
        }}
      >
        <Select
          options={countryOptions}
          value={countryOptions.find((opt) => opt.value === selectedCountry)}
          onChange={handleCountryChange}
          isDisabled={disabled}
          styles={{
            control: (base) => ({
              ...base,
              minWidth: "120px",
              border: "none",
              backgroundColor: "transparent",
            }),
          }}
        />

        <input
          id={id}
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneChange}
          disabled={disabled}
          placeholder="Enter phone number"
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "transparent",
            fontSize: "16px",
            paddingLeft: "10px",
            outline: "none",
          }}
          {...props}
        />
      </Box>

      <div style={{ minHeight: "16px", color: "red", fontSize: "12px" }}>
        {error || localError}
      </div>
    </FormControl>
  );
};

export default PhoneInputField;
