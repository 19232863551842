import React, { useEffect, useState } from "react";
import MyContainer from "../../myComponent/MyContainer";
import Filters from "../../components/Filters";
import { CustomBtn } from "../../myComponent/CustomBtn";
import InfiniteScrollList from "../../myComponent/InfiniteScrollList";
import NoDataFound from "../../pages/User/components/NoDataFound";
import { useNavigate } from "react-router-dom";
import CardHeader from "../../components/CardHeader";
import { leadHeaderAllItems, leadHeaderItems } from "../../utils/menuItemCrm";
import { AddIcon } from "@chakra-ui/icons";
import LeadListItem from "./component/LeadListItem";
import { Box, IconButton, Spinner, useDisclosure } from "@chakra-ui/react";
import { useLeadQuery } from "./useLeadQuery/useLeadQuery";
import AddNote from "./component/AddNote";
import ChangeStatus from "./component/ChangeStatus";
import { useProfileQuery } from "../../Queries/auth/useProfileQuery";
import { adminArr, rolesNotAgentTeam } from "../../utils/menuItems";
import filter from "../../assets/filter.svg";
import LeadFilterSort from "./component/LeadFilterSort";
import CallComponent from "./component/CallComponent";
import CountHeader from "./component/CountHeader";
import Confirmation from "../../components/Confirmation";
import { svg } from "../../assets/svg";
import { deleteLead } from "../../apiCRM/leadApi";
import AssignLead from "./component/AssignLead";

const LeadList = () => {
  const defaultStatus = "new";
  const [leadStatus, setLeadStatus] = useState(() => {
    return localStorage.getItem("selectedLeadStatus") || defaultStatus;
  });
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { data: auth } = useProfileQuery();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectIdsType, setSelectIdsType] = useState("");
  const notTeamAgent = rolesNotAgentTeam.includes(auth?.role);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteLoad, isDeleteLoad] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [filterState, setFilterState] = useState({
    source: "",
    agentArrFilter: {},
    countryCode: "",
    startCreatedDate: "",
    endCreatedDate: "",
    startAssignedDate: "",
    endAssignedDate: "",
    startUpdatedDate: "",
    endUpdatedDate: "",
    sortOrder: {},
    sortBy: {},
  });

  const [isLoadingData, setIsLoadingData] = useState(false);
  const {
    data: allLead,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    refetch,
  } = useLeadQuery({
    search,
    status: leadStatus,
    source: filterState?.source?.value,
    countryCode: filterState?.countryCode,
    sortBy: filterState?.sortBy?.value,
    sortOrder: filterState?.sortOrder?.value,
    startCreatedDate: filterState?.startCreatedDate,
    endCreatedDate: filterState?.endCreatedDate,
    startUpdatedDate: filterState?.startUpdatedDate,
    endUpdatedDate: filterState?.endUpdatedDate,
    startAssignedDate: filterState?.startAssignedDate,
    endAssignedDate: filterState?.endAssignedDate,
    agentArrFilter: JSON.stringify(
      Array.isArray(filterState?.agentArrFilter)
        ? filterState.agentArrFilter.map((item) => item._id)
        : []
    ),
  });

  const handleOpenModal = (type, data) => {
    setModalType(type);
    setSelectedLeadData(data);
    onOpen();
  };

  useEffect(() => {
    // refetch();
    localStorage.setItem("selectedLeadStatus", leadStatus);
  }, [leadStatus]);

  useEffect(() => {
    refetch();
  }, [filterState]);

  const buttonConfigs = [
    {
      title: "Reassign",
      actionType: "Reassign",
      onClick: () =>
        navigate("/lead/assign-lead", { state: { actionType: "Reassign" } }),
    },
  ];

  const handleFilterChange = (values) => {
    setIsLoadingData(true);
    setFilterState(values);
    // refetch();
  };
  const handleClearFilter = () => {
    setIsLoadingData(true);
    setFilterState({
      source: null,
      agentArrFilter: null,
      countryCode: null,
      startCreatedDate: null,
      endCreatedDate: null,
      startAssignedDate: null,
      endAssignedDate: null,
      startUpdatedDate: null,
      endUpdatedDate: null,
      sortOrder: null,
      sortBy: null,
    });
  };

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setIsLoadingData(false);
    }
  }, [isFetching, isLoading]);

  const statusItem = leadHeaderAllItems?.find(
    (item) => item?.value === leadStatus
  );
  const statusTitle = statusItem?.label || "N/A";
  const statusColor = statusItem?.color || "#000";

  const handleClickCheckbox = ({ ids, type }) => {
    if (type === "selectAll") {
      // console.log("itemselectall", ids);
      setSelectIdsType(type);
      setSelectedIds(ids);
    }

    if (type === "singleSelect") {
      // console.log("itemSingleSelect", ids);
      setSelectIdsType(type);
      const value = ids[0];
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(value)) {
          return prevSelectedIds.filter((id) => id !== value);
        } else {
          return [...prevSelectedIds, value];
        }
      });
    }
  };

  const handleDeleteLead = async () => {
    isDeleteLoad(true);
    try {
      if (selectedIds) {
        const sendData = [...selectedIds];
        console.log("sendData", sendData);
        const res = await deleteLead({ ids: sendData });
        closeModal();
        setSelectedIds([]);
      }
      refetch();
    } catch (err) {
      console.log(err);
    } finally {
      isDeleteLoad(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedIds([]);
    onClose();
  };

  useEffect(() => {
    setSelectedIds([]);
  }, [leadStatus]);

  return (
    <>
      <MyContainer
        header="Leads"
        subHeader={"Lead List"}
        isheaderWhite
        btnComponent={
          <>
            {/* Filters Component */}
            <Filters onSearchChange={setSearch} />

            {selectedIds?.length > 0 && (
              <>
                {adminArr?.includes(auth?.role) && (
                  <IconButton
                    colorScheme="white"
                    icon={<img src={svg.leadDelete} alt="Delete" />}
                    onClick={openModal}
                  />
                )}
                {(adminArr?.includes(auth?.role) || auth?.isLeadPermission) && (
                  <IconButton
                    colorScheme="white"
                    icon={<img src={svg.assignLead} alt="Assign" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal("assign");
                    }}
                  />
                )}
              </>
            )}

            {/* Filter Icon Button */}
            <IconButton
              colorScheme="white"
              icon={<img src={filter} alt="Filter" />}
              onClick={() => setIsFilterOpen(true)}
            />

            {/* Add Lead Icon */}
            <AddIcon
              style={{
                backgroundColor: "#9A4D49",
                borderRadius: "5px",
                padding: "5px",
                fontSize: "25px",
                color: "white",
                cursor: "pointer",
                fontWeight: "800",
                boxShadow:
                  "black 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              }}
              onClick={() => navigate("/lead/addlead")}
            />

            {/* Render custom buttons if `notTeamAgent` is true */}
            {/* {notTeamAgent && buttonConfigs?.length > 0 && (
              <>
                {buttonConfigs.map((btnConfig, index) => (
                  <CustomBtn
                    key={index}
                    title={btnConfig.title}
                    onClick={btnConfig.onClick}
                  />
                ))}
              </>
            )} */}
            {notTeamAgent && buttonConfigs?.length > 0 ? (
              <>
                {buttonConfigs.map((btnConfig, index) => (
                  <CustomBtn
                    key={index}
                    title={btnConfig.title}
                    onClick={btnConfig.onClick}
                  />
                ))}
              </>
            ) : (
              auth?.isLeadPermission && (
                <>
                  {buttonConfigs.map((btnConfig, index) => (
                    <CustomBtn
                      key={index}
                      title={btnConfig.title}
                      onClick={btnConfig.onClick}
                    />
                  ))}
                </>
              )
            )}
          </>
        }
      >
        <CardHeader
          value={leadStatus}
          items={leadHeaderAllItems}
          onChange={(v) => setLeadStatus(v)}
          defaultStatus={defaultStatus}
          module="lead"
          containerStyle={{ marginTop: { base: "5rem", md: "inherit" } }}
        />
        <CountHeader
          data={allLead}
          statusTitle={statusTitle || "N/A"}
          leadCount={totalItems}
          module={"Leads"}
          color={statusColor}
          onSelectAll={handleClickCheckbox}
          onDeleteClick={openModal}
          selectedId={selectedIds}
        />
        {isLoadingData || isLoading ? (
          <Box textAlign="center" my={4}>
            <Spinner size="md" mt={"10rem"} />
            <p>Loading Leads...</p>
          </Box>
        ) : (
          <InfiniteScrollList
            data={allLead || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            isFetching={isFetching}
            renderItem={(item) => (
              <LeadListItem
                item={item}
                onClickBox={() =>
                  navigate("/lead/lead-details", { state: item })
                }
                checkedBoxCount={selectedIds}
                onClickCheckbox={(e) => handleClickCheckbox(e)}
                onClickEdit={(e) => {
                  e.stopPropagation();
                  // handleOpenModal("call", item);
                  navigate("/lead/addlead", { state: item });
                }}
                onClickCall={(e) => {
                  e.stopPropagation();
                  handleOpenModal("call", item);
                }}
                onClickConversations={(e) => {
                  e.stopPropagation();
                  navigate("/conversation");
                }}
                onClickNotes={(e) => {
                  e.stopPropagation();
                  handleOpenModal("notes", item);
                }}
                onClickMeetings={(e) => {
                  e.stopPropagation();
                  navigate("/meetings/create-meetings", { state: item });
                  console.log("log", item);
                }}
                onStatusChange={(e) => {
                  e.stopPropagation();
                  handleOpenModal("statusChange", item);
                }}
                leadStatus={leadStatus}
              />
            )}
            loadingMessage="Loading Leads..."
            errorMessage="Error fetching Leads"
            noDataMessage={
              <NoDataFound message="No Lead In The System" name="NoUser" />
            }
            gap={2}
            containerStyle={{ marginTop: "1rem" }}
          />
        )}
        <LeadFilterSort
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          onApply={handleFilterChange}
          initialValues={filterState}
          onClear={handleClearFilter}
        />
        <Confirmation
          line1="Are you sure you want to delete"
          line2="the Lead"
          onClose={closeModal}
          isOpen={isModalOpen}
          onSubmit={handleDeleteLead}
          isBtnLoading={deleteLoad}
        />
      </MyContainer>
      {modalType === "assign" && (
        <AssignLead
          isOpen={isOpen}
          onClose={handleCloseModal}
          data={selectedIds}
          refetch={refetch}
        />
      )}

      {modalType === "notes" && (
        <AddNote
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
      {modalType === "statusChange" && (
        <ChangeStatus
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
      {modalType === "call" && (
        <CallComponent
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default LeadList;
